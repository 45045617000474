<template>
  <v-app>
    <top-bar></top-bar>
    <div class="top-bar-spacer primary"></div>
    <v-main>
      <router-view />
    </v-main>
    <rodape></rodape>
  </v-app>
</template>

<script>
import TopBar from '@/components/common/TopBar.vue'
import Rodape from '@/components/common/Rodape.vue'
import FimDeAno from '@/components/extras/FimDeAno.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init()

export default {
  components: {
    TopBar,
    Rodape,
    FimDeAno,
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.top-bar-spacer {
  display: block;
  width: 100%;
  height: 64px;
}
</style>
