<template>
  <div id="rodape" class="primary white--text pa-8">
    <div class="content">
      <v-row align="start" justify="center" class="fill-height text-center">
        <!-- COLUNA LOGO -->
        <v-col cols="12" xs="12" sm="6" md="5" class="text-left">
          <img src="@/assets/logos/pisco_light.svg" style="width: 160px;" />
          <div class="body-1 mt2 pr-0 pr-12">
            Ganhe agilidade, economia e flexibilidade com as soluções Pisco.
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="2" class="text-center">
          <div class="section text-left">
            <h3 class="accent--text mb-4">Navegue</h3>
            <a href="/" class="link">Home</a>
            <a href="/blog" class="link">Blog</a>
            <a href="/servicos" class="link">Serviços</a>
            <a href="/contato" class="link">Fale conosco</a>
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="2" class="text-left">
          <div class="section">
            <h3 class="accent--text mb-4">Soluções</h3>
            <a href="/siscontrol" class="link">SisControl</a>
            <a href="/" class="link">Matriculando</a>
            <a href="/" class="link">Apps</a>
            <a href="/" class="link">Produtos</a>
          </div>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="3" class="text-left">
          <div class="section">
            <h3 class="accent--text mb-4">Atendimento</h3>
            <div class="body-1 font-weight-bold">+55 (11) 2091-1206</div>

            <v-btn
              outlined
              href="https://api.whatsapp.com/send?phone=+5511999957055"
              target="_blank"
              color="white"
              class="mt-4"
            >
              <v-icon class="mr-2">mdi-whatsapp</v-icon> Whatsapp
            </v-btn>

            <a href="mailto:comercial@piscoidc.com.br" class="link mt-4"
              ><v-icon color="white">mdi-email-outline</v-icon> comercial@piscoidc.com.br</a
            >
            <div class="body-2 mt-4">Rua Cantagalo, 692, 13º andar</div>
            <div class="body-2">Tatuapé - São Paulo - SP</div>
            <div class="body-2">CEP: 03319-000</div>
          </div>
        </v-col>
      </v-row>

      <v-row align="start" justify="start" no-gutters>
        <v-col cols="12">
          <div class="divider"></div>
          <div class="body-2">
            {{ ano }} Pisco - Soluções para controle de acesso - Todos os direitos reservados.
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rodape',
  data() {
    return {
      ano: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="scss" scoped>
#rodape .content {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  line-height: 28px;
  margin: 0 auto;

  .link {
    display: block;
    text-decoration: none;
    color: white;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #00aeef;
    }
  }
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0.2;
  margin: 8px 0px;
}
</style>
