<template>
  <v-menu top rounded="lg">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on"><v-icon class="accent--text">mdi-menu</v-icon></v-btn>
    </template>
    <v-list class="d-sm-none">
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon class="accent--text">mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <v-list-item to="/servicos">
        <v-list-item-icon>
          <v-icon class="accent--text">mdi-shield-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Serviços</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item to="/siscontrol">
        <v-list-item-icon>
          <v-icon class="accent--text">mdi-turnstile</v-icon>
        </v-list-item-icon>
        <v-list-item-title>SisControl</v-list-item-title>
      </v-list-item>

      <v-list-item to="/matriculando">
        <v-list-item-icon>
          <v-icon class="accent--text">mdi-badge-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Matriculando</v-list-item-title>
      </v-list-item>

      <v-list-item to="/apps">
        <v-list-item-icon>
          <v-icon class="accent--text">mdi-cellphone</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Apps</v-list-item-title>
      </v-list-item>

      <v-list-item to="/blog">
        <v-list-item-icon>
          <v-icon class="accent--text">mdi-post-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Blog</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item to="/contato">
        <v-list-item-icon>
          <v-icon class="accent--text">mdi-forum</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Fale Conosco</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item href="https://api.whatsapp.com/send?phone=+5511999957055" target="_blank">
        <v-list-item-icon>
          <v-icon class="accent--text">mdi-whatsapp</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Whatsapp</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {}
</script>
