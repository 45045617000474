<template>
  <v-app-bar fixed dark class="topbar" elevate-on-scroll elevation="10" height="64">
    <div
      class="pisco-logo"
      :class="$vuetify.breakpoint.xs ? 'pisco-logo-center' : 'pisco-logo-big'"
      @click="$router.push('/home')"
    ></div>
    <div class="body-2 ml-2 d-none d-md-block">Soluções em Controle de Acesso</div>
    <v-spacer></v-spacer>
    <div id="buttons-container" class="d-none d-sm-block">
      <v-btn text small to="/">HOME</v-btn>

      <v-menu bottom open-on-hover offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text small v-bind="attrs" v-on="on">SOLUÇÕES</v-btn>
        </template>
        <v-list>
          <v-list-item to="/siscontrol">SisControl</v-list-item>
          <v-list-item to="/matriculando">Matriculando</v-list-item>
          <v-list-item to="/apps">Apps</v-list-item>
          <v-list-item to="/produtos">Produtos</v-list-item>
        </v-list>
      </v-menu>

      <v-btn text small to="servicos">SERVIÇOS</v-btn>

      <v-btn text small to="blog">BLOG</v-btn>

      <v-btn text small to="contato" class="mr-8">FALE CONOSCO</v-btn>
      <v-btn icon small href="https://api.whatsapp.com/send?phone=+5511999957055" target="_blank">
        <v-icon size="18">mdi-whatsapp</v-icon>
      </v-btn>
    </div>

    <div class="d-sm-none">
      <drop-menu></drop-menu>
    </div>
  </v-app-bar>
</template>

<script>
import DropMenu from '@/components/common/DropMenu.vue'

export default {
  components: {
    DropMenu,
  },
}
</script>

<style lang="scss">
.pisco-logo {
  cursor: pointer;
}

.pisco-logo-big {
  display: block;
  width: 73px;
  height: 40px;
  background-image: url('~@/assets/logos/pisco_light.svg');
  background-size: contain;
}

.pisco-logo-center {
  display: block;
  width: 100%;
  height: 40px;
  background-image: url('~@/assets/logos/pisco_light.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.topbar {
  background-color: transparent !important;
  backdrop-filter: blur(10px);
  .v-toolbar__content {
    background-color: rgba(45, 56, 130, 0.86) !important;
  }
}
</style>
