<template>
  <!-- MENSAGEM DE FIM DE ANO -->
  <v-dialog v-model="dialog" max-width="600">
    <v-card @click="dialog = false" elevation="12">
      <v-img
        src="@/assets/extras/fim_de_ano.jpg"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
      >
        <div class="titlex">Boas Festas!</div>
      </v-img>
      <v-card-text class="pt-6 text-body-1">
        <p>
          Comunicamos que a Pisco entrará em recesso a partir do dia
          <strong>21/12</strong> retornando às atividades normais no dia <strong>05/01</strong>,
          período em que não haverá atendimento via telefone fixo. Manteremos o atendimento via
          <strong>WhatsApp</strong> funcionando durante esse período.
        </p>
        <p>
          A Pisco deseja um feliz natal e um próspero ano novo a todos!
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');

.titlex {
  font-family: 'Pinyon Script', cursive;
  font-size: 48px;
  text-align: right;
  padding: 16px 32px;
}
</style>
