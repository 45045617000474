<template>
  <v-row
    class="section flex-row-reverse"
    align="center"
    align-content="center"
    justify="center"
    no-gutters
  >
    <v-col cols="12" md="6">
      <div class="img"></div>
    </v-col>
    <v-col cols="12" md="6" align-self="center">
      <div class="text-container pa-8 pa-md-12">
        <div
          class="text-title"
          data-aos="fade-right"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-duration="1500"
        >
          Para quem <span>é indicado</span>
        </div>
        <ul class="text-list">
          <li
            data-aos="fade-right"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-delay="100"
            data-aos-duration="1500"
          >
            Edifícios Comerciais
          </li>
          <li
            data-aos="fade-right"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-delay="200"
            data-aos-duration="1500"
          >
            Escolas
          </li>
          <li
            data-aos="fade-right"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            Academias
          </li>
          <li
            data-aos="fade-right"
            data-aos-mirror="false"
            data-aos-once="true"
            data-aos-delay="400"
            data-aos-duration="1500"
          >
            Indústrias
          </li>
        </ul>
        <div
          data-aos="fade-right"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-delay="500"
          data-aos-duration="1500"
        >
          <p class="text">
            e qualquer outra corporação que necessite de alguma solução para controlar o acesso de
            pessoas em determinados pontos.
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'indicado',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.section {
  background-color: #2d3882;
}

.img {
  background-image: url('~@/assets/outros/image-2.jpg');
  background-size: cover;
  background-position: center center;
  min-height: 490px;
}

.text-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  align-content: stretch;
  justify-content: center;
  color: white;

  .text-title {
    display: block;
    flex: 1 1 auto;
    font-size: 38px;
    font-weight: bold;
    padding-bottom: 24px;

    span {
      color: #00adee;
    }
  }

  .text-list {
    padding-bottom: 24px;
    opacity: 0.6;
  }

  .text {
    opacity: 0.6;
  }
}
</style>
