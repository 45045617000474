import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: Home,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/siscontrol',
    name: 'SisControl',
    component: () => import(/* webpackChunkName: "siscontrol" */ '../views/SisControl.vue'),
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import(/* webpackChunkName: "agenda" */ '../views/Agenda.vue'),
  },
  {
    path: '/matriculando',
    name: 'Matriculando',
    component: () => import(/* webpackChunkName: "matriculando" */ '../views/Matriculando.vue'),
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import(/* webpackChunkName: "apps" */ '../views/Apps.vue'),
  },
  {
    path: '/produtos',
    name: 'Produtos',
    component: () => import(/* webpackChunkName: "produtos" */ '../views/Produtos.vue'),
  },
  {
    path: '/servicos',
    name: 'Servicos',
    component: () => import(/* webpackChunkName: "servicos" */ '../views/Servicos.vue'),
  },
  {
    path: '/contato',
    name: 'Contato',
    component: () => import(/* webpackChunkName: "contato" */ '../views/Contato.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router
