<template>
  <v-row align="start" justify="center" no-gutters class="software-row">
    <template v-for="software in softwares">
      <v-col
        :cols="cols.total"
        :xs="cols.xs"
        :sm="cols.sm"
        :md="cols.md"
        :lg="cols.lg"
        :xl="cols.xl"
        align="center"
        justify="center"
        :key="software.name"
        class="pa-8 software-col"
      >
        <div class="software-logo">
          <img :src="software.img" />
        </div>
        <p class="body-2 my-4 white--text software-text">{{ software.text }}</p>
        <v-btn outlined color="white" :to="software.link">Saiba mais</v-btn>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      softwares: [
        {
          name: 'SisControl',
          text:
            'O SisControl é um sistema de controle de acesso utilizado em variados locais, que exigem diferentes níveis e tipos de restrição, controle e monitoramento do fluxo de pessoas.',
          img: require('@/assets/logos/siscontrol.svg'),
          link: '/siscontrol',
        },
        {
          name: 'SisControl Chegou',
          text:
            'Nossa solução para a redução de trânsito em portas de escolas. Tenha mais organização na retirada de alunos por pais e responsáveis e aumente a proteção da sua escola.',
          img: require('@/assets/logos/siscontrol_chegou.svg'),
          link: '/siscontrol',
        },
        {
          name: 'SisControl Agenda',
          text:
            'Gere mais valor para seu condomínio e reduza seus custos de operação. Com o Siscontrol Agenda é possível enviar convites personalizados de acesso para visitantes via Whatsapp e e-mail com configurações de acesso personalizadas.',
          img: require('@/assets/logos/siscontrol_agenda.svg'),
          link: '/agenda',
        },
      ],
      cols: { total: 12, xs: 12, sm: 12, md: 4, lg: 4, xl: 4 },
    }
  },
}
</script>

<style lang="scss" scoped>
.software-row {
  background-color: #2d3882;
}

.software-col {
  max-width: 560px;
}

.software-text {
  widows: 100%;
  max-width: 280px;
}

.software-logo {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 260px;
  height: 75px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
