<template>
  <v-responsive width="100%" max-width="1200" class="prod-section">
    <div class="px-6 pt-6 pb-0">
      <h1 class="pa-0 ma-0 primary--text font-weight-light">Produtos</h1>
    </div>
    <v-row align="center" align-content="center" justify="center" class="pa-6">
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="3"
        v-for="(produto, index) in produtos"
        :key="index"
        @click="$router.push('produtos')"
      >
        <div
          data-aos="zoom-in-down"
          :data-aos-delay="index * factor"
          :data-aos-once="true"
          data-aos-duration="1200"
        >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 0 : 0" class="prod-card">
              <v-card-text class="pa-0">
                <v-responsive class="prod-wrapper" width="100%" max-width="600">
                  <v-responsive class="produto" width="100%" :aspect-ratio="370 / 221">
                    <img
                      class="prod-img"
                      :src="require(`@/assets/produtos/${produto.img}.jpg`)"
                      alt=""
                    />
                  </v-responsive>
                  <h3 class="primary--text font-weight-light py-4">{{ produto.title }}</h3>
                  <div class="prod-line"></div>
                </v-responsive>
              </v-card-text>
            </v-card>
          </v-hover>
        </div>
      </v-col>
    </v-row>
  </v-responsive>
</template>

<script>
export default {
  name: 'produtos',
  data() {
    return {
      factor: this.$vuetify.breakpoint.smAndDown ? 0 : 100,
      produtos: [
        { title: 'CATRACAS', img: 'prod-1' },
        { title: 'BARREIRAS FÍSICAS', img: 'prod-2' },
        { title: 'ELETROIMÃS', img: 'prod-3' },
        { title: 'CANCELAS', img: 'prod-4' },
        { title: 'PORTARIA REMOTA E CFTV', img: 'prod-5' },
        { title: 'PLACA CONTROLADORA', img: 'prod-6' },
        { title: 'QRCODE', img: 'prod-7' },
        { title: 'IDENTIFICAÇÃO', img: 'prod-9' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.prod-section {
  margin: auto;
  text-align: left;
}

.prod-card {
  transition: all 0.2s ease-in-out;
}

.prod-wrapper {
  margin: auto;
  cursor: pointer;
  text-align: left;

  .produto {
    position: relative;
    background-color: #2d3882;
    padding: 0px;
    margin: 0px;
    font-size: 0px;
    overflow: hidden;
    border-radius: 5px;

    .prod-img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 101%;
      height: auto;
      transition: all 0.2s ease-in-out;
      opacity: 1;
    }
  }

  .prod-line {
    display: block;
    width: 20%;
    height: 2px;
    background-color: rgb(177, 177, 177);
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    .produto {
      .prod-img {
        width: 110%;
        opacity: 0.6;
      }
    }

    .prod-line {
      width: 100%;
      background-color: #2d3882;
    }
  }
}
</style>
