<template>
  <v-container fluid class="pa-0 ma-0">
    <carousel></carousel>
    <softwares></softwares>
    <produtos></produtos>
    <indicado></indicado>
    <beneficios></beneficios>
    <conheca></conheca>
  </v-container>
</template>

<script>
import Beneficios from '@/components/home/Beneficios.vue'
import Carousel from '@/components/home/Carousel.vue'
import Conheca from '@/components/home/Conheca.vue'
import Indicado from '@/components/home/Indicado.vue'
import Produtos from '@/components/home/Produtos.vue'
import Softwares from '@/components/home/Softwares.vue'

export default {
  components: {
    Beneficios,
    Carousel,
    Conheca,
    Indicado,
    Produtos,
    Softwares,
  },
}
</script>
