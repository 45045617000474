<template>
  <v-responsive width="100%" height="560" class="parallax">
    <v-row align="center" justify="start" class="fill-height content pa-0 pa-sm-12">
      <v-col cols="12" class="pa-12" style="max-width: 800px">
        <div class="white--text text-sm-h2 text-h3 font-weight-bold">
          Conheça a <br /><span class="accent--text">Pisco Sistemas</span>
        </div>
        <p class="white--text body-1 my-6">
          Mais de duas décadas aliando tecnologia própria a projetos de consultoria para
          implementação de controle de acesso.
        </p>
        <p class="white--text body-1 my-6">
          Em nosso portfólio você encontra soluções ágeis e inovadoras para gestão de pessoas,
          acessos e segurança.
        </p>
        <p class="white--text body-1 mt-6">
          Nosso objetivo é ofertar tecnologia de ponta para acelerar o seu negócio.
        </p>
      </v-col>
    </v-row>
  </v-responsive>
</template>

<script>
export default {
  name: 'conheca',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.parallax {
  background-image: url('~@/assets/outros/image-1.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .content {
    background-color: rgba(0, 0, 0, 0.72);
  }
}
</style>
