<template>
  <v-responsive width="100%" :aspect-ratio="16 / 9" max-height="760">
    <v-carousel
      v-model="model"
      height="100%"
      cycle
      :interval="8000"
      show-arrows-on-hover
      hide-delimiter-background
    >
      <!-- CAROUSEL ITEM -->
      <v-carousel-item>
        <v-sheet height="100%" tile class="slide slide-1">
          <v-row class="fill-height" align="center" justify="center">
            <v-responsive width="100%" max-width="1000px" class="pa-8">
              <div class="slide-title"><span>TECNOLOGIA</span> DE PONTA</div>
              <div class="slide-text">para controle de acesso</div>
              <div class="slide-actions">
                <v-btn
                  to="/servicos"
                  rounded
                  outlined
                  :x-large="$vuetify.breakpoint.smAndUp"
                  color="white"
                  >Saiba mais</v-btn
                >
              </div>
            </v-responsive>
          </v-row>
        </v-sheet>
      </v-carousel-item>

      <!-- CAROUSEL ITEM -->
      <v-carousel-item>
        <v-sheet height="100%" tile class="slide slide-2">
          <v-row class="fill-height" align="center" justify="center">
            <v-responsive width="100%" max-width="1000px" class="pa-8">
              <div class="slide-title"><span>RETROFIT</span></div>
              <div class="slide-text" style="max-width: 520px;">
                Adaptação e atualização de todo o parque tecnológico de controle de acesso.
              </div>
              <div class="slide-actions">
                <v-btn
                  to="/siscontrol"
                  rounded
                  outlined
                  :x-large="$vuetify.breakpoint.smAndUp"
                  color="white"
                  >Saiba mais</v-btn
                >
              </div>
            </v-responsive>
          </v-row>
        </v-sheet>
      </v-carousel-item>

      <!-- CAROUSEL ITEM -->
      <v-carousel-item>
        <v-sheet height="100%" tile class="slide slide-5">
          <v-row class="fill-height" align="center" justify="center">
            <v-responsive width="100%" max-width="1000px" class="pa-8">
              <div class="slide-title"><span>SISCONTROL</span> AGENDA</div>
              <div class="slide-text" style="max-width: 520px;">
                Agendamento prévio e envio de convite de acesso para visitantes
              </div>
              <div class="slide-actions">
                <v-btn
                  to="/agenda"
                  rounded
                  outlined
                  :x-large="$vuetify.breakpoint.smAndUp"
                  color="white"
                  >Saiba mais</v-btn
                >
              </div>
            </v-responsive>
          </v-row>
        </v-sheet>
      </v-carousel-item>

      <!-- CAROUSEL ITEM -->
      <v-carousel-item>
        <v-sheet height="100%" tile class="slide slide-3">
          <v-row class="fill-height" align="center" justify="center">
            <v-responsive width="100%" max-width="1000px" class="pa-8">
              <div class="slide-title"><span>APPS</span></div>
              <div class="slide-text">A segurança na palma da sua mão.</div>
              <div class="slide-actions">
                <v-btn
                  to="/apps"
                  rounded
                  outlined
                  :x-large="$vuetify.breakpoint.smAndUp"
                  color="white"
                  >Saiba mais</v-btn
                >
              </div>
            </v-responsive>
          </v-row>
        </v-sheet>
      </v-carousel-item>

      <!-- CAROUSEL ITEM -->
      <v-carousel-item>
        <v-sheet height="100%" tile class="slide slide-4">
          <v-row class="fill-height" align="center" justify="center">
            <v-responsive width="100%" max-width="1000px" class="pa-8">
              <div class="slide-title"><span>MATRICULANDO</span></div>
              <div class="slide-text">Cadastro e gerenciamento de pais, alunos e professores.</div>
              <div class="slide-actions">
                <v-btn
                  to="/matriculando"
                  rounded
                  outlined
                  :x-large="$vuetify.breakpoint.smAndUp"
                  color="white"
                  >Saiba mais</v-btn
                >
              </div>
            </v-responsive>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-responsive>
</template>

<script>
export default {
  data() {
    return {
      model: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
.slide {
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;

  .slide-title {
    display: block;
    font-size: 2em;
    font-weight: bold;
    color: white;
    padding-bottom: 12px;

    span {
      color: #00adee;
    }
  }

  .slide-text {
    display: block;
    font-size: 1em;
    font-weight: 300;
    color: white;
    padding-bottom: 28px;
  }
}

@media only screen and (min-width: 600px) {
  .slide {
    .slide-title {
      font-size: 4em;
      padding-bottom: 22px;
    }

    .slide-text {
      font-size: 1.4em;
      padding-bottom: 38px;
    }
  }
}

.slide-1 {
  background-image: url('~@/assets/home_carousel/image-1.jpg');
}

.slide-2 {
  background-image: url('~@/assets/home_carousel/image-2.jpg');
}

.slide-3 {
  background-image: url('~@/assets/home_carousel/image-3.jpg');
}

.slide-4 {
  background-image: url('~@/assets/home_carousel/image-4.jpg');
}

.slide-5 {
  background-image: url('~@/assets/agenda/hero.jpg');
}
</style>
