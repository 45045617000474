<template>
  <v-responsive width="100%" max-width="920px" class="mx-auto pa-8 text-center">
    <h1 class="text-center ma-0 primary--text">Benefícios Pisco</h1>
    <div class="subtitle-1 mb-8 text--disabled">Conheça alguns benefícios das soluções Pisco</div>
    <v-row align="center" justify="center" no-gutters>
      <!-- BENEFÍCIO -->
      <v-col cols="12" sm="6" md="4" v-for="(beneficio, idx) in beneficios" :key="idx">
        <div data-aos="fade-up" :data-aos-delay="idx * factor" :data-aos-duration="1000">
          <v-responsive width="100%" :aspect-ratio="1 / 1" class="ma-auto pa-0 box">
            <!-- INNER BOX -->
            <v-responsive
              width="90%"
              :aspect-ratio="1 / 0.84"
              class="inner-box mx-auto elevation-2"
            >
              <div class="box-title">{{ beneficio.titulo }}</div>
              <div class="box-text">{{ beneficio.text }}</div>
            </v-responsive>

            <!-- CIRCLE ICON -->
            <div class="inner-circle elevation-4 accent">
              <img :src="require(`@/assets/beneficios/${beneficio.icon}.png`)" alt="" />
            </div>
          </v-responsive>
        </div>
      </v-col>
    </v-row>

    <h1 class="text-center mt-8 mb-2 primary--text">Chame a Pisco para te ajudar!</h1>

    <v-row align="start" justify="center">
      <v-col cols="12" xs="12" sm="6" md="3" class="body-2 text--disabled text-center">
        <div class="number accent--text">1</div>
        Entre em contato com nossa equipe por telefone ou aqui mesmo pelo site!
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3" class="body-2 text--disabled text-center">
        <div class="number accent--text">2</div>
        Nós criamos um projeto personalizado para as suas necessidades totalmente gratuito.
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3" class="body-2 text--disabled text-center">
        <div class="number accent--text">3</div>
        Nosso time implementa a solução em tempo hábil e realizar todo processo de treinamento e
        acompanhamento.
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="3" class="body-2 text--disabled text-center">
        <div class="number accent--text">4</div>
        Tenha previsão em seu orçamento. Com a Pisco, você tem preço fechado de manutenção.
      </v-col>
    </v-row>
  </v-responsive>
</template>

<script>
export default {
  name: 'beneficios',
  data() {
    return {
      factor: this.$vuetify.breakpoint.smAndDown ? 0 : 250,
      beneficios: [
        {
          titulo: 'Eficiência',
          text:
            'Controle do fluxo de passagem de usuários em diversos pontos de acesso com atualização instantânea de status.',
          icon: 'n-1',
        },
        {
          titulo: 'Economia',
          text:
            'Nosso preço fechado de manutenção e suporte garante maior controle financeiro para seu edifício.',
          icon: 'n-2',
        },
        {
          titulo: 'Segurança',
          text:
            'Faixas de horários, Anti PassBack, cadastros com localização. Tudo para garantir a integridade em seu controle de acesso.',
          icon: 'n-3',
        },
        {
          titulo: 'Velocidade',
          text:
            'O Atendimento Pisco é o mais rápido do mercado. Totalmente humanizado, acompanhamos de perto todo o processo de resolução de qualquer problema que ocorra em seu controle de acesso.',
          icon: 'n-4',
        },
        {
          titulo: 'Continuidade',
          text:
            'Inovações constantes e exclusivas desenvolvidas por nossa Software House com mais de 20 anos de atuação no mercado.',
          icon: 'n-5',
        },
        {
          titulo: 'Versatilidade',
          text:
            'Não importa qual seja a sua necessidade, nossa equipe encontrará a melhor solução para suas exigências e cenário de controle.',
          icon: 'n-6',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.box {
  position: relative;

  .inner-box {
    background-color: #ececec;
    border-radius: 7px;
    overflow: hidden;
    position: absolute;
    bottom: 5%;
    text-align: center;
    color: #797979;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);

    .box-title {
      margin-top: 14%;
      font-weight: 500;
      font-size: 22px;
    }

    .box-text {
      margin-top: 6px;
      font-size: 14px;
      padding: 8px;
    }
  }

  .inner-circle {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    overflow: hidden;
    width: 30%;
    height: 30%;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
}

.number {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  font-size: 48px;
  font-weight: 500;
  margin: 0px auto 12px auto;
  font-family: 'Caveat', cursive;
}
</style>
